// React
import { useState, useEffect } from "react";

// Constants
import {
  sexs,
  schools,
  tshirtSizes,
  groups,
  attendanceTypes,
} from "assets/constants";

// Functions
import { getLabel, switchIcons } from "assets/functions";

// Date fns
import { parse, format } from "date-fns";
import { es } from "date-fns/locale";

// Current User
import useUser from "assets/hooks/useUser";

// Material
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  ListItemIcon,
  Typography,
} from "@mui/material";

// Chart
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from "recharts";

// Theming
import theme from "theme";

import {
  getUserBaptismCertificateDownloadURL,
  updateUserBaptismCertificateStorage,
} from "assets/plugins/firebase/storage";

// Axios
import {
  confirmandSelf,
  attendances,
  patchUser,
  patchConfirmand,
} from "assets/plugins/axios";

// Components
import Loading from "components/Loading";
import DisplayEditField from "components/DisplayEditField";
import DisplayCheckField from "components/DisplayCheckField";
import DisplayAutoComplete from "components/DisplayAutoComplete";
import DisplayDatePicker from "components/DisplayDatePicker";
import DisplayEditAvatar from "components/DisplayEditAvatar";
import DisplayEditImage from "components/DisplayEditImage";
import CollapseSection from "components/CollapseSection";
import Alert from "components/Alert";
import Error from "components/Error";
import ConfirmDialog from "components/ConfirmDialog";

// Log
import { logger } from "assets/plugins/log";

const ConfirmandProfile = () => {
  const { currentUser } = useUser();
  const user_uid = currentUser.uid;

  const [ConfirmandState, setConfirmandState] = useState({});
  const [InitialLoadingState, setInitialLoadingState] = useState(true);
  const [LoadErrorState, setLoadErrorState] = useState({
    status: false,
    message: "",
  });
  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [ConfirmDialogState, setConfirmDialogState] = useState({
    open: false,
    loading: false,
    title: "",
    description: "",
    confirmText: "",
    cancelText: "",
    onConfirm: () => {},
  });
  const [AttendanceLoadingState, setAttendanceLoadingState] = useState(false);
  const [AttendanceState, setAttendanceState] = useState([]);

  const editPermission = false;

  const fieldsMap = {
    id: {
      fieldName: "id",
      expand: "",
    },
    address: {
      fieldName: "address",
      expand: "",
    },
    telephone: {
      fieldName: "telephone",
      expand: "",
    },
    birthdate: {
      fieldName: "birthdate",
      expand: "",
    },
    school: {
      fieldName: "school",
      expand: "",
    },
    tshirt_size: {
      fieldName: "tshirt_size",
      expand: "",
    },
    is_fee_paid: {
      fieldName: "is_fee_paid",
      expand: "",
    },
    legal_id: {
      fieldName: "legal_id",
      expand: "",
    },
    allergies: {
      fieldName: "allergies",
      expand: "",
    },
    conditions: {
      fieldName: "conditions",
      expand: "",
    },
    medications: {
      fieldName: "medications",
      expand: "",
    },
    comments: {
      fieldName: "comments",
      expand: "",
    },
    confirmation_godparent_first_name: {
      fieldName: "confirmation_godparent_first_name",
      expand: "",
    },
    confirmation_godparent_second_name: {
      fieldName: "confirmation_godparent_second_name",
      expand: "",
    },
    confirmation_godparent_last_name: {
      fieldName: "confirmation_godparent_last_name",
      expand: "",
    },
    confirmation_godparent_second_last_name: {
      fieldName: "confirmation_godparent_second_last_name",
      expand: "",
    },
    is_first_communion_done: {
      fieldName: "is_first_communion_done",
      expand: "",
    },
    baptism_certificate_link: {
      fieldName: "baptism_certificate_link",
      expand: "",
    },
    "confirmation_group.id": {
      fieldName: "confirmation_group.id",
      expand: "confirmation_group",
    },
    "confirmation_group.level": {
      fieldName: "confirmation_group.level",
      expand: "confirmation_group",
    },
    "user.id": {
      fieldName: "user.id",
      expand: "user",
    },
    "user.uid": {
      fieldName: "user.uid",
      expand: "user",
    },
    "user.email": {
      fieldName: "user.email",
      expand: "user",
    },
    "user.first_name": {
      fieldName: "user.first_name",
      expand: "user",
    },
    "user.second_name": {
      fieldName: "user.second_name",
      expand: "user",
    },
    "user.last_name": {
      fieldName: "user.last_name",
      expand: "user",
    },
    "user.second_last_name": {
      fieldName: "user.second_last_name",
      expand: "user",
    },
    "user.cellphone": {
      fieldName: "user.cellphone",
      expand: "user",
    },
    "user.sex": {
      fieldName: "user.sex",
      expand: "user",
    },
    "relationships.id": {
      fieldName: "relationships.id",
      expand: "relationships",
    },
    "relationships.relationship": {
      fieldName: "relationships.relationship",
      expand: "relationships",
    },
    "relationships.guardian": {
      fieldName: "relationships.guardian",
      expand: "relationships",
    },
    mass_attendance: {
      fieldName: "mass_attendance",
      expand: "",
    },
    catechesis_attendance: {
      fieldName: "catechesis_attendance",
      expand: "",
    },
  };

  const initialFields = [];
  const initialExpand = [];

  for (const field of Object.values(fieldsMap)) {
    initialFields.push(field.fieldName);
    if (!initialExpand.includes(field.expand)) {
      initialExpand.push(field.expand);
    }
  }

  const params = {
    user_uid,
    fields: initialFields.toString(),
    expand: initialExpand.toString(),
  };

  useEffect(() => {
    const getConfirmand = async () => {
      try {
        const { data: response } = await confirmandSelf({
          token: currentUser.token,
          params,
        });

        const data = response.results[0];

        const catechesisAttendance = Object.entries(data.catechesis_attendance)
          .map(([key, value]) => {
            return {
              name: getLabel(attendanceTypes, key),
              value: value,
            };
          })
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        const massAttendance = Object.entries(data.mass_attendance)
          .map(([key, value]) => {
            return {
              name: getLabel(attendanceTypes, key),
              value: value,
            };
          })
          .sort((a, b) => (b.name > a.name ? -1 : 1));

        let baptism_certificate_link = "";
        try {
          baptism_certificate_link = await getUserBaptismCertificateDownloadURL(
            data.user.uid,
          );
        } catch (error) {
          // setErrorState({status: true, message: `${error}. Intente de nuevo.`,});
        }
        setConfirmandState({
          user_uid: data.user.uid,
          user_id: data.user.id,
          id: data.id,
          first_name: data.user.first_name,
          second_name: data.user.second_name,
          last_name: data.user.last_name,
          second_last_name: data.user.second_last_name,
          email: data.user.email,
          cellphone: data.user.cellphone,
          telephone: data.telephone,
          address: data.address,
          birthdate: parse(data.birthdate, "yyyy-MM-dd", new Date()),
          school: data.school,
          tshirt_size: data.tshirt_size,
          sex: data.user.sex,
          confirmation_group: data.confirmation_group.id,
          level: data.confirmation_group.level,
          is_fee_paid: data.is_fee_paid,
          legal_id: data.legal_id,
          baptism_certificate_link: baptism_certificate_link,
          is_first_communion_done: data.is_first_communion_done,
          confirmation_godparent_first_name:
            data.confirmation_godparent_first_name,
          confirmation_godparent_second_name:
            data.confirmation_godparent_second_name,
          confirmation_godparent_last_name:
            data.confirmation_godparent_last_name,
          confirmation_godparent_second_last_name:
            data.confirmation_godparent_second_last_name,
          allergies: data.allergies,
          conditions: data.conditions,
          medications: data.medications,
          comments: data.comments,
          catechesisAttendance,
          massAttendance,
          imageUrl: `https://api.dicebear.com/7.x/bottts/svg?seed=${data.user.uid}`,
        });
        setInitialLoadingState(false);
      } catch (error) {
        if (error.name === "FirebaseError") {
          // Do nothing
        } else {
          logger("[Confirmand Profile] Error getting confirmand.", {
            payload: { error },
            source: "/confirmand/profile",
            level: "error",
          });
          setLoadErrorState({
            status: true,
            message: error.message,
          });
        }
        setInitialLoadingState(false);
      }
    };

    getConfirmand();
    logger("[Confirmand Profile] page loaded.", {
      payload: { currentUser },
      source: "/confirmand/profile",
    });
  }, [currentUser.token]);

  const onEditSave = (data) => {
    setConfirmandState({
      ...ConfirmandState,
      ...data,
    });
  };

  const onAttendanceClick = async () => {
    try {
      setAttendanceLoadingState(true);
      const { data } = await attendances({
        token: currentUser.token,
        params: {
          search: ConfirmandState.user_id,
          fields:
            "id,attendance,comments,activity.date,activity.activity_type,activity.id",
          expand: "activity",
        },
      });
      const formattedAttendances = data.results
        .map((attendance) => {
          return {
            id: attendance.id,
            date: parse(
              attendance.activity.date,
              "yyyy-MM-dd'T'HH:mm:ssxxx",
              new Date(),
            ),
            activity_type: attendance.activity.activity_type,
            attendance: attendance.attendance,
            comments: attendance.comments,
          };
        })
        .sort((a, b) => (b.date > a.date ? -1 : 1));
      setAttendanceState(formattedAttendances);
    } catch (error) {
      setErrorState({
        open: true,
        message: `${error}. Intente de nuevo.`,
      });
    } finally {
      setAttendanceLoadingState(false);
    }
  };

  if (InitialLoadingState) return <Loading />;
  if (LoadErrorState.status)
    return <Error errorMessage={LoadErrorState.message} />;

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: theme.palette.primary.light,
            borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
          }}
        >
          <DisplayEditAvatar
            userUid={ConfirmandState.user_uid}
            imageUrl={ConfirmandState.imageUrl}
            token={currentUser.token}
            disabled
          />
          <Typography variant="h1">{`${ConfirmandState.first_name} ${ConfirmandState.last_name}`}</Typography>
        </Paper>
      </Grid>
      {/* Asistencia */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection
            title="Asistencia"
            loading={AttendanceLoadingState}
            onClick={onAttendanceClick}
            defaultExpanded={false}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper elevation={0} style={{ padding: "3%" }}>
                  <Typography variant="h3" paddingBottom="1%">
                    Catequesis
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend verticalAlign="bottom" />
                      <Pie
                        data={ConfirmandState.catechesisAttendance}
                        legendType="line"
                        innerRadius={50}
                        dataKey="value"
                        nameKey="name"
                        label
                      >
                        <Cell fill={theme.palette.success.dark} />
                        <Cell fill={theme.palette.error.dark} />
                        <Cell fill={theme.palette.info.dark} />
                        <Cell fill={theme.palette.warning.dark} />
                        <Cell fill={theme.palette.warning.main} />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={0} style={{ padding: "3%" }}>
                  <Typography variant="h3" paddingBottom="1%">
                    Misas
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Legend verticalAlign="bottom" />
                      <Pie
                        data={ConfirmandState.massAttendance}
                        legendType="line"
                        innerRadius={50}
                        dataKey="value"
                        nameKey="name"
                        label
                      >
                        <Cell fill={theme.palette.success.dark} />
                        <Cell fill={theme.palette.error.dark} />
                        <Cell fill={theme.palette.info.dark} />
                        <Cell fill={theme.palette.warning.dark} />
                        <Cell fill={theme.palette.warning.main} />
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={0} style={{ padding: "1%" }}>
                  <Typography variant="h3" padding="0 0 2%">
                    Listado de asistencias
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Tipo</TableCell>
                          <TableCell>Fecha</TableCell>
                          <TableCell>Asistencia</TableCell>
                          <TableCell>Comentarios</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {AttendanceState.map((attendance) => (
                          <TableRow key={attendance.id}>
                            <TableCell>{attendance.activity_type}</TableCell>
                            <TableCell>
                              {format(attendance.date, "dd 'de' MMMM", {
                                locale: es,
                              })}
                            </TableCell>
                            <TableCell
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ListItemIcon>
                                {switchIcons(attendance.attendance)}
                              </ListItemIcon>
                              {getLabel(attendanceTypes, attendance.attendance)}
                            </TableCell>
                            <TableCell width="50%">
                              {attendance.comments}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Confirmando  */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Confirmando">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { first_name: value },
                    })
                  }
                  type="text"
                  label="Nombre"
                  defaultValue={ConfirmandState.first_name}
                  name="first_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { second_name: value },
                    })
                  }
                  type="text"
                  label="Segundo Nombre"
                  defaultValue={ConfirmandState.second_name}
                  name="second_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { last_name: value },
                    })
                  }
                  type="text"
                  label="Apellido"
                  defaultValue={ConfirmandState.last_name}
                  name="last_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { second_last_name: value },
                    })
                  }
                  type="text"
                  label="Segundo Apellido"
                  defaultValue={ConfirmandState.second_last_name}
                  name="second_last_name"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { email: value },
                    })
                  }
                  type="email"
                  label="Correo electrónico"
                  defaultValue={ConfirmandState.email}
                  name="email"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { cellphone: value },
                    })
                  }
                  type="text"
                  label="Celular"
                  defaultValue={ConfirmandState.cellphone}
                  name="cellphone"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { telephone: value },
                    })
                  }
                  type="text"
                  label="Teléfono fijo"
                  defaultValue={ConfirmandState.telephone}
                  name="telephone"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4} lg={3} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { address: value },
                    })
                  }
                  type="text"
                  label="Dirección"
                  defaultValue={ConfirmandState.address}
                  name="address"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                <DisplayDatePicker
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { birthdate: value },
                    })
                  }
                  label="Fecha de nacimiento"
                  loading={InitialLoadingState}
                  defaultValue={ConfirmandState.birthdate}
                  name="birthdate"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={4} lg={3} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { school: value },
                    })
                  }
                  options={schools}
                  label="Colegio"
                  defaultValue={ConfirmandState.school}
                  name="school"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2} lg={3} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { tshirt_size: value },
                    })
                  }
                  options={tshirtSizes}
                  label="Talla"
                  defaultValue={ConfirmandState.tshirt_size}
                  name="tshirt_size"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchUser({
                      token: currentUser.token,
                      id: ConfirmandState.user_id,
                      data: { sex: value },
                    })
                  }
                  options={sexs}
                  label="Sexo"
                  defaultValue={ConfirmandState.sex}
                  name="sex"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                <DisplayAutoComplete
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { confirmation_group: value },
                    })
                  }
                  options={groups[ConfirmandState.level]}
                  label="Grupo"
                  defaultValue={ConfirmandState.confirmation_group}
                  name="confirmation_group"
                  uid={ConfirmandState.user_id}
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { legal_id: value },
                    })
                  }
                  type="text"
                  label="Cédula"
                  defaultValue={ConfirmandState.legal_id}
                  name="legal_id"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={8} sm={6} md={4} lg={3}>
                <DisplayCheckField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { is_fee_paid: value },
                    })
                  }
                  defaultValue={ConfirmandState.is_fee_paid}
                  label="Canceló la cuota"
                  name="is_fee_paid"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Vida sacramental */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Vida sacramental">
            <Grid container spacing={5}>
              {/* Bautismo */}
              <Grid item sm={12} md={6} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">Certificado de Bautismo</Typography>
                </Grid>
                <Grid item xs={12}>
                  <DisplayEditImage
                    disabled={!editPermission}
                    defaultImageURL={ConfirmandState.baptism_certificate_link}
                    saveImage={(image) =>
                      updateUserBaptismCertificateStorage(
                        image,
                        ConfirmandState.user_uid,
                      )
                    }
                  />
                </Grid>
              </Grid>
              {/* Primera comunión */}
              <Grid item sm={12} md={6} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">Primera comunión</Typography>
                </Grid>
                <Grid item xs={12}>
                  <DisplayCheckField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          is_first_communion_done: value,
                        },
                      })
                    }
                    label="Recibió el sacramento"
                    defaultValue={ConfirmandState.is_first_communion_done}
                    name="is_first_communion_done"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
              </Grid>
              {/* Confirmacion */}
              <Grid item sm={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Padrino o Madrina de Confirmación
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <DisplayEditField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          confirmation_godparent_first_name: value,
                        },
                      })
                    }
                    type="text"
                    label="Nombre"
                    defaultValue={
                      ConfirmandState.confirmation_godparent_first_name
                    }
                    name="confirmation_godparent_first_name"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DisplayEditField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          confirmation_godparent_second_name: value,
                        },
                      })
                    }
                    type="text"
                    label="Segundo nombre"
                    defaultValue={
                      ConfirmandState.confirmation_godparent_second_name
                    }
                    name="confirmation_godparent_second_name"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DisplayEditField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          confirmation_godparent_last_name: value,
                        },
                      })
                    }
                    type="text"
                    label="Apellido"
                    defaultValue={
                      ConfirmandState.confirmation_godparent_last_name
                    }
                    name="confirmation_godparent_last_name"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DisplayEditField
                    patch={(value) =>
                      patchConfirmand({
                        token: currentUser.token,
                        id: ConfirmandState.id,
                        data: {
                          confirmation_godparent_second_last_name: value,
                        },
                      })
                    }
                    type="text"
                    label="Segundo apellido"
                    defaultValue={
                      ConfirmandState.confirmation_godparent_second_last_name
                    }
                    name="confirmation_godparent_second_last_name"
                    onSave={onEditSave}
                    disabled={!editPermission}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      {/* Salud */}
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: "3%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CollapseSection title="Salud">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { allergies: value },
                    })
                  }
                  type="text"
                  label="Alergias"
                  multiline
                  defaultValue={ConfirmandState.allergies}
                  name="allergies"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { medications: value },
                    })
                  }
                  type="text"
                  label="Medicamentos"
                  multiline
                  defaultValue={ConfirmandState.medications}
                  name="medications"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <DisplayEditField
                  patch={(value) =>
                    patchConfirmand({
                      token: currentUser.token,
                      id: ConfirmandState.id,
                      data: { conditions: value },
                    })
                  }
                  type="text"
                  label="Condiciones"
                  multiline
                  defaultValue={ConfirmandState.conditions}
                  name="conditions"
                  onSave={onEditSave}
                  disabled={!editPermission}
                />
              </Grid>
            </Grid>
          </CollapseSection>
        </Paper>
      </Grid>
      <ConfirmDialog
        open={ConfirmDialogState.open}
        loading={ConfirmDialogState.loading}
        title={ConfirmDialogState.title}
        description={ConfirmDialogState.description}
        onConfirm={ConfirmDialogState.onConfirm}
        onClose={() =>
          setConfirmDialogState({
            open: false,
            loading: false,
          })
        }
        confirmText={ConfirmDialogState.confirmText}
        confirmColor="error"
        cancelText={ConfirmDialogState.cancelText}
      />
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default ConfirmandProfile;
