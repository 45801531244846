// React
import { useState, useEffect } from "react";

// Material
import { Paper, Grid, Typography, Button } from "@mui/material";

// Routing
import { useHistory } from "react-router-dom";

// Components
import CatechistsTable from "components/CatechistsTable";
import Alert from "components/Alert";
import Error from "components/Error";
import Loading from "components/Loading";

// Axios
import { catechists as catechistsQuery } from "assets/plugins/axios";

// User
import useUser from "assets/hooks/useUser";

// Log
import { logger } from "assets/plugins/log";

// lodash
import { merge } from "lodash";

// flat
import { flatten } from "flat";

const AdminCatechists = () => {
  const initialGridConfigState = {
    columns: {
      columnVisibilityModel: {
        id: false,
        "user.first_name": true,
        "user.second_name": false,
        "user.last_name": true,
        "user.sex": false,
        "confirmation_group.group": false,
        "catechesis_attendance.attendance": false,
        "catechesis_attendance.absence": false,
        "catechesis_attendance.late": false,
        "catechesis_attendance.notifiedabsence": false,
        "catechesis_attendance.excusedabsence": false,
        "mass_attendance.attendance": false,
        "mass_attendance.absence": false,
        "mass_attendance.late": false,
        "mass_attendance.notifiedabsence": false,
        "mass_attendance.excusedabsence": false,
      },
    },
  };

  const fieldsMap = {
    id: {
      fieldName: "id",
      expand: "",
      isInitial: true,
    },
    "user.first_name": {
      fieldName: "user.first_name",
      expand: "user",
      isInitial: true,
    },
    "user.last_name": {
      fieldName: "user.last_name",
      expand: "user",
      isInitial: true,
    },
    "user.sex": {
      fieldName: "user.sex",
      expand: "user",
      isInitial: false,
    },
    "confirmation_group.group": {
      fieldName: "confirmation_group.group",
      expand: "confirmation_group",
      isInitial: true,
    },
    "user.second_name": {
      fieldName: "user.second_name",
      expand: "user",
      isInitial: false,
    },
    "catechesis_attendance.attendance": {
      fieldName: "catechesis_attendance.attendance",
      expand: "",
      isInitial: false,
    },
    "catechesis_attendance.absence": {
      fieldName: "catechesis_attendance.absence",
      expand: "",
      isInitial: false,
    },
    "catechesis_attendance.late": {
      fieldName: "catechesis_attendance.late",
      expand: "",
      isInitial: false,
    },
    "catechesis_attendance.notifiedabsence": {
      fieldName: "catechesis_attendance.notifiedabsence",
      expand: "",
      isInitial: false,
    },
    "catechesis_attendance.excusedabsence": {
      fieldName: "catechesis_attendance.excusedabsence",
      expand: "",
      isInitial: false,
    },
    "mass_attendance.attendance": {
      fieldName: "mass_attendance.attendance",
      expand: "",
      isInitial: false,
    },
    "mass_attendance.absence": {
      fieldName: "mass_attendance.absence",
      expand: "",
      isInitial: false,
    },
    "mass_attendance.late": {
      fieldName: "mass_attendance.late",
      expand: "",
      isInitial: false,
    },
    "mass_attendance.notifiedabsence": {
      fieldName: "mass_attendance.notifiedabsence",
      expand: "",
      isInitial: false,
    },
    "mass_attendance.excusedabsence": {
      fieldName: "mass_attendance.excusedabsence",
      expand: "",
      isInitial: false,
    },
  };

  const { currentUser } = useUser();

  const canSeeOtherGroups = currentUser.claims.permissions.includes(
    "view_all_level_catechists",
  );

  const history = useHistory();

  const [ErrorState, setErrorState] = useState({
    status: false,
    message: "",
  });
  const [LoadingState, setLoadingState] = useState(false);
  const [CatechistsState, setCatechistsState] = useState([]);
  const [GridConfigState, setGridConfigState] = useState(
    initialGridConfigState,
  );

  const initialFields = [];
  const initialExpand = [];

  for (const field of Object.values(fieldsMap)) {
    if (field.isInitial) {
      initialFields.push(field.fieldName);
      if (!initialExpand.includes(field.expand)) {
        initialExpand.push(field.expand);
      }
    }
  }

  const params = {
    fields: initialFields.toString(),
    expand: initialExpand.toString(),
  };

  useEffect(() => {
    const getCatechists = async () => {
      const response = await catechistsQuery({
        token: currentUser.token,
        params,
      });
      const catechists = response.data.results.map((rawCatechist) => {
        const catechist = {};
        const flattenedCatechist = flatten(rawCatechist);

        for (let key in flattenedCatechist) {
          catechist[key] = flattenedCatechist[key];
        }

        return catechist;
      });

      setCatechistsState(catechists);
    };

    try {
      getCatechists();

      logger("[Admin Catechists] page loaded.", {
        payload: { currentUser },
        source: "/admin/catechists",
      });
    } catch (error) {
      setErrorState({
        status: true,
        message: error.message,
      });
      setLoadingState(false);
    }
  }, [currentUser.token, currentUser.claims.level]);

  const onColumnVisibilityModelChange = async (newModel) => {
    const changedColumns = [];

    for (const columnName in newModel) {
      if (
        newModel[columnName] &&
        !Object.prototype.hasOwnProperty.call(CatechistsState[0], columnName)
      ) {
        changedColumns.push(columnName);
      }
    }

    if (changedColumns.length > 0) {
      const fields = `id,${changedColumns.toString()}`;
      const expand = Array.from(
        new Set(
          changedColumns.map(
            (changedColumn) => fieldsMap[changedColumn].expand,
          ),
        ),
      ).toString();

      setLoadingState(true);

      const newCatechistsQuery = await catechistsQuery({
        token: currentUser.token,
        params: {
          fields,
          expand,
        },
      });

      const newCatechistsData = newCatechistsQuery.data.results.map(
        (catechist) => {
          const newCatechist = {};
          const flattenedCatechist = flatten(catechist);

          for (let key in flattenedCatechist) {
            newCatechist[key] = flattenedCatechist[key];
          }

          return newCatechist;
        },
      );

      setCatechistsState((prevState) => merge(prevState, newCatechistsData));
      setGridConfigState((prevState) => ({
        ...prevState,
        columns: {
          ...prevState.columns,
          columnVisibilityModel: newModel,
        },
      }));
      setLoadingState(false);
    }
  };

  if (LoadingState) return <Loading />;
  if (ErrorState.status) return <Error />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: "1%" }}>
          <Typography variant="h1">Catequistas</Typography>
        </Paper>
      </Grid>
      {currentUser.claims.permissions.includes("add_catechist") ? (
        <Grid item xs={12} textAlign="end">
          <Button
            name="agregar"
            variant="contained"
            onClick={() => history.push("/admin/catechists/add")}
          >
            AGREGAR CATEQUISTA
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <CatechistsTable
          rows={CatechistsState}
          showGroups={canSeeOtherGroups}
          initialGridConfigState={GridConfigState}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        />
      </Grid>
      <Alert
        open={ErrorState.status}
        severity="error"
        message={ErrorState.message}
        onClose={() =>
          setErrorState({
            status: false,
            message: "",
          })
        }
      />
    </Grid>
  );
};

export default AdminCatechists;
