// Prop Types
import PropTypes from "prop-types";

// Material
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";

// Routing
import { useHistory } from "react-router-dom";

// Material Icons
import Check from "@mui/icons-material/CheckCircleOutline";
import Cancel from "@mui/icons-material/CancelOutlined";
import Late from "@mui/icons-material/AssignmentLateOutlined";
import LocalHospital from "@mui/icons-material/LocalHospital";
import MarkEmailRead from "@mui/icons-material/MarkEmailRead";
import WineBarTwoToneIcon from "@mui/icons-material/WineBarTwoTone";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";

const CatechistsTable = ({
  rows,
  showGroups,
  initialGridConfigState,
  onColumnVisibilityModelChange,
}) => {
  const history = useHistory();

  const pageSize = Math.floor(window.innerHeight * 0.014);

  const toggleSexIcons = (row) =>
    row.value === "F" ? <FemaleIcon /> : <MaleIcon />;

  const columns = [
    {
      field: "id",
      hideable: false,
    },
    {
      field: "user.first_name",
      headerName: "Nombre",
      type: "string",
      hideable: false,
    },
    {
      field: "user.second_name",
      headerName: "Segundo nombre",
      type: "string",
    },
    {
      field: "user.last_name",
      headerName: "Apellido",
      type: "string",
    },
    {
      field: "user.sex",
      headerName: "Sexo",
      description: "Sexo",
      type: "string",
      renderCell: toggleSexIcons,
    },
    {
      field: "catechesis_attendance.attendance",
      headerName: "Asistencias a catequesis",
      description: "Asistencias a catequesis",
      type: "number",
      renderHeader: () => (
        <>
          <MenuBookTwoToneIcon />
          <Check color="success" />
        </>
      ),
    },
    {
      field: "catechesis_attendance.absence",
      headerName: "Ausencias a catequesis",
      description: "Ausencias a catequesis",
      type: "number",
      renderHeader: () => (
        <>
          <MenuBookTwoToneIcon />
          <Cancel color="error" />
        </>
      ),
    },
    {
      field: "catechesis_attendance.late",
      headerName: "Tardanzas a catequesis",
      description: "Tardanzas a catequesis",
      type: "number",
      renderHeader: () => (
        <>
          <MenuBookTwoToneIcon />
          <Late color="warning" />
        </>
      ),
    },
    {
      field: "catechesis_attendance.notifiedabsence",
      headerName: "Ausencias notificadas a catequesis",
      description: "Ausencias notificadas a catequesis",
      type: "number",
      renderHeader: () => (
        <>
          <MenuBookTwoToneIcon />
          <MarkEmailRead color="warning" />
        </>
      ),
    },
    {
      field: "catechesis_attendance.excusedabsence",
      headerName: "Ausencias justificadas a catequesis",
      description: "Ausencias justificadas a catequesis",
      type: "number",
      renderHeader: () => (
        <>
          <MenuBookTwoToneIcon />
          <LocalHospital color="info" />
        </>
      ),
    },
    {
      field: "mass_attendance.attendance",
      headerName: "Asistencias a misa",
      description: "Asistencias a misa",
      type: "number",
      renderHeader: () => (
        <>
          <WineBarTwoToneIcon />
          <Check color="success" />
        </>
      ),
    },
    {
      field: "mass_attendance.absence",
      headerName: "Ausencias a misa",
      description: "Ausencias a misa",
      type: "number",
      renderHeader: () => (
        <>
          <WineBarTwoToneIcon />
          <Cancel color="error" />
        </>
      ),
    },
    {
      field: "mass_attendance.late",
      headerName: "Tardanzas a misa",
      description: "Tardanzas a misa",
      type: "number",
      renderHeader: () => (
        <>
          <WineBarTwoToneIcon />
          <Late color="warning" />
        </>
      ),
    },
    {
      field: "mass_attendance.notifiedabsence",
      headerName: "Ausencias notificadas a misa",
      description: "Ausencias notificadas a misa",
      type: "number",
      renderHeader: () => (
        <>
          <WineBarTwoToneIcon />
          <MarkEmailRead color="warning" />
        </>
      ),
    },
    {
      field: "mass_attendance.excusedabsence",
      headerName: "Ausencias justificadas a misa",
      description: "Ausencias justificadas a misa",
      type: "number",
      renderHeader: () => (
        <>
          <WineBarTwoToneIcon />
          <LocalHospital color="info" />
        </>
      ),
    },
  ];

  if (showGroups) {
    columns.push({
      field: "confirmation_group.group",
      headerName: "Grupo",
      type: "number",
    });
  }

  return (
    <DataGrid
      onCellClick={(evt) => history.push(`/admin/catechist/${evt.row.id}`)}
      disableRowSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      initialState={initialGridConfigState}
      slots={{ toolbar: GridToolbar }}
      slotProps={{ toolbar: { showQuickFilter: true } }}
      style={{
        backgroundColor: "white",
        height: "90vh",
      }}
    />
  );
};

CatechistsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  showGroups: PropTypes.bool.isRequired,
  initialGridConfigState: PropTypes.any,
  onColumnVisibilityModelChange: PropTypes.func,
};

export default CatechistsTable;
