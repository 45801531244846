// Material
import Check from "@mui/icons-material/CheckCircleOutline";
import Cancel from "@mui/icons-material/CancelOutlined";
import Late from "@mui/icons-material/AssignmentLateOutlined";
import LocalHospital from "@mui/icons-material/LocalHospital";
import MarkEmailRead from "@mui/icons-material/MarkEmailRead";

const switchIcons = (type) => {
  switch (type) {
    case "attendance":
      return <Check color="success" />;
    case "absence":
      return <Cancel color="error" />;
    case "late":
      return <Late color="warning" />;
    case "notifiedabsence":
      return <MarkEmailRead color="warning" />;
    case "excusedabsence":
      return <LocalHospital color="info" />;
    default:
      break;
  }
};

export default switchIcons;
